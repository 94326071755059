import React from 'react';
import { array, bool, object, string } from 'prop-types';
import { normalizeTemplateName } from './listHelpers';
import ListRow from './list-row/ListRow';
import WidgetHeader from '@/components/widgets/WidgetHeader';
import ListHero from './ListHero';
import {
	getDataType,
	getWidgetCategory,
	getThemeClassName,
	getContentListFromGalleryFolders
} from '@/components/widgets/widgetHelpers';
import SeeMore from '@/components/widgets/SeeMore';
import { useWidgetContext } from '@/components/widgets/WidgetContext';
import './ListBreaking.scss';

const seeMoreAnalyticsData = {
	'data-analytics-linkname': 'see-more',
	'data-analytics-linklocation': 'list-widget'
};

const MAX_LIST_ITEMS = 5;

/**
 * @function ListBreaking
 * @param {Object} props
 * @param {Object} [props.additionalContentLink] see more link
 * @param {Array} props.contentList
 * @param {Object} props.criteria
 * @param {String} [props.header]
 * @param {Boolean} props.isCurated
 * @param {String} [props.position]
 * @param {Boolean} [props.authorBylineEnabled]
 * @param {Boolean} [props.timestampsEnabled]
 * @param {String} props.widgetTemplate - used to determine options
 * @param {String} [props.pageTheme]
 * @returns {React.ReactElement}
 */
const ListBreaking = ({
	additionalContentLink,
	contentList,
	criteria,
	header,
	isCurated,
	position,
	widgetTemplate,
	authorBylineEnabled,
	timestampsEnabled,
	pageTheme
}) => {
	const { isThumbLazyLoadDisabled } = useWidgetContext();
	const widgetTemplateName = normalizeTemplateName(widgetTemplate);
	const widgetAnalyticsData = {
		'data-widget-type': 'list-widget',
		'data-widget-data-type': getDataType(criteria, isCurated),
		'data-widget-category': getWidgetCategory(criteria, isCurated),
		'data-widget-position': position,
		'data-widget-template': widgetTemplate
	};
	const updatedContentList = getContentListFromGalleryFolders(contentList);

	const hero = updatedContentList?.[0];
	return updatedContentList && updatedContentList.length > 0 ? (
		<section
			className={`widget list-widget widget--with-border ${widgetTemplateName} ${
				getThemeClassName(pageTheme) || 'widget--dark'
			}`}
			data-hook="list-widget"
		>
			<WidgetHeader
				title={header}
				isWithLine={false}
				uri={hero?.reference?.uri}
				analyticsData={widgetAnalyticsData}
			/>
			<div className="columns">
				<div className="column is-7-desktop is-full-tablet">
					<ListHero
						analyticsData={widgetAnalyticsData}
						{...hero}
						timestampsEnabled={timestampsEnabled}
						authorBylineEnabled={authorBylineEnabled}
						isThumbLazyLoadDisabled={isThumbLazyLoadDisabled}
					/>
				</div>
				<div className="list__content column is-5-desktop is-full-tablet">
					{updatedContentList.map((item, index) => {
						if (index === 0 || !item?.reference || index >= MAX_LIST_ITEMS) return null;
						return (
							<ListRow
								key={`${item.id}-${index}`}
								{...item}
								analyticsData={widgetAnalyticsData}
								isCurated={isCurated}
								options={{
									showThumbs: true,
									stackedTablet: true,
									thumbType: '16x9',
									hideAuthors: !authorBylineEnabled,
									hideDate: !timestampsEnabled
								}}
								widgetTemplate={widgetTemplate}
								isThumbLazyLoadDisabled={isThumbLazyLoadDisabled}
							/>
						);
					})}
				</div>
			</div>
			<SeeMore
				analyticsData={seeMoreAnalyticsData}
				link={additionalContentLink?.reference?.uri}
			/>
		</section>
	) : null;
};

ListBreaking.propTypes = {
	additionalContentLink: object,
	contentList: array.isRequired,
	criteria: object.isRequired,
	header: string,
	isCurated: bool.isRequired,
	timestampsEnabled: bool,
	authorBylineEnabled: bool,
	position: string,
	widgetTemplate: string.isRequired,
	pageTheme: string
};

ListBreaking.displayName = 'ListBreaking';

export default ListBreaking;
