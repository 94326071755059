import React from 'react';
import { bool, array, object, string } from 'prop-types';
import Hero from '@/components/widgets/hero/Hero';
import { useWidgetContext } from '@/components/widgets/WidgetContext';

/**
 * @function ListHero
 * @param {Object} props
 * @param {Object} [props.analyticsData]
 * @param {Array} [props.authors]
 * @param {String} [props.displayTitle]
 * @param {Object} props.displayImage
 * @param {String} [props.linkType]
 * @param {Object} props.reference
 * @param {Boolean} [props.authorBylineEnabled]
 * @param {Boolean} [props.timestampsEnabled]
 * @returns {React.ReactElement}
 */
const ListHero = ({
	analyticsData,
	authors,
	displayTitle,
	displayImage,
	linkType,
	reference,
	authorBylineEnabled,
	timestampsEnabled
}) => {
	const { isThumbLazyLoadDisabled } = useWidgetContext();
	if (!reference) return null;
	return (
		<Hero
			analyticsData={analyticsData}
			key={reference.id}
			id={reference.id}
			authors={authors}
			publishDate={reference.publishDate}
			width="660"
			thumbType="16x9"
			thumbnail={displayImage}
			title={displayTitle}
			type={linkType}
			{...reference}
			authorBylineEnabled={authorBylineEnabled}
			timestampsEnabled={timestampsEnabled}
			isThumbLazyLoadDisabled={isThumbLazyLoadDisabled}
		/>
	);
};

ListHero.propTypes = {
	analyticsData: object,
	authors: array,
	displayTitle: string,
	displayImage: object.isRequired,
	linkType: string,
	reference: object.isRequired,
	timestampsEnabled: bool,
	authorBylineEnabled: bool
};

ListHero.displayName = 'ListHero';

export default ListHero;
