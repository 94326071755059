import React from 'react';
import { array, bool, object, string } from 'prop-types';
import { getListOptions, normalizeTemplateName } from './listHelpers';
import ListRow from './list-row/ListRow';
import WidgetHeader from '@/components/widgets/WidgetHeader';
import ListHero from './ListHero';
import { useWidgetContext } from '@/components/widgets/WidgetContext';

import {
	getDataType,
	getWidgetCategory,
	getThemeClassName,
	getContentListFromGalleryFolders
} from '@/components/widgets/widgetHelpers';
import SeeMore from '@/components/widgets/SeeMore';
import ListBreaking from './ListBreaking';
import './ListWidget.scss';

const seeMoreAnalyticsData = {
	'data-analytics-linkname': 'see-more',
	'data-analytics-linklocation': 'list-widget'
};

const MAX_LIST_ITEMS = 5;
/**
 * @function ListWidget
 * @param {Object} props
 * @param {Object} [props.additionalContentLink] see more link
 * @param {Array} props.contentList
 * @param {Object} props.criteria
 * @param {String} [props.header]
 * @param {Object} [props.headerImage]
 * @param {Boolean} props.isCurated
 * @param {String} [props.position]
 * @param {Boolean} [props.authorBylineEnabled]
 * @param {Boolean} [props.timestampsEnabled]
 * @param {String} props.widgetTemplate - used to determine options
 * @param {String} [props.pageTheme]
 * @returns {React.ReactElement}
 */
const ListWidget = (props) => {
	const { isThumbLazyLoadDisabled } = useWidgetContext();
	const {
		additionalContentLink,
		contentList,
		criteria,
		header,
		headerImage,
		isCurated,
		position,
		widgetTemplate,
		authorBylineEnabled,
		timestampsEnabled,
		pageTheme
	} = props;
	const widgetTemplateName = normalizeTemplateName(widgetTemplate);
	const options = getListOptions(widgetTemplate);
	const widgetAnalyticsData = {
		'data-widget-type': 'list-widget',
		'data-widget-data-type': getDataType(criteria, isCurated),
		'data-widget-category': getWidgetCategory(criteria, isCurated),
		'data-widget-position': position,
		'data-widget-template': widgetTemplate
	};
	if (widgetTemplate === 'LIST_BREAKING') return <ListBreaking {...props} />;

	const updatedContentList = getContentListFromGalleryFolders(contentList);

	return updatedContentList && updatedContentList.length > 0 ? (
		<section
			className={`widget list-widget ${widgetTemplateName} ${getThemeClassName(pageTheme)}`}
			data-hook="list-widget"
			data-tb-region={`list-widget ${widgetTemplateName} ${position}`}
		>
			<WidgetHeader title={header} thumbnail={headerImage} />
			<div className="list__content">
				{updatedContentList.map((item, index) => {
					const showListHero =
						index === 0 && options.showHero && Boolean(item?.displayImage);
					if (!item?.reference || index >= MAX_LIST_ITEMS) return null;

					const key = `${position}-list-item-${index}`;

					return showListHero ? (
						<ListHero
							key={key}
							analyticsData={widgetAnalyticsData}
							{...item}
							timestampsEnabled={timestampsEnabled}
							authorBylineEnabled={authorBylineEnabled}
						/>
					) : (
						<ListRow
							key={key}
							{...item}
							analyticsData={widgetAnalyticsData}
							count={index + 1}
							isCurated={isCurated}
							options={{
								...options,
								hideAuthors: !authorBylineEnabled,
								hideDate: !timestampsEnabled
							}}
							widgetTemplate={widgetTemplate}
							isThumbLazyLoadDisabled={isThumbLazyLoadDisabled}
						/>
					);
				})}
			</div>
			<SeeMore
				analyticsData={seeMoreAnalyticsData}
				link={additionalContentLink?.reference?.uri}
			/>
		</section>
	) : null;
};

ListWidget.defaultProps = {
	widgetTemplate: 'LIST_HERO_TEXT'
};

ListWidget.propTypes = {
	additionalContentLink: object,
	contentList: array.isRequired,
	criteria: object.isRequired,
	header: string,
	headerImage: object,
	isCurated: bool.isRequired,
	timestampsEnabled: bool,
	authorBylineEnabled: bool,
	position: string,
	widgetTemplate: string.isRequired,
	pageTheme: string
};

ListWidget.displayName = 'ListWidget';

export default ListWidget;
