const hideIcon = true;
const showCount = true;
const showHero = true;
const showThumbs = true;
const showThumbCount = true;
const stacked = true;

/**
 * @function getListOptions
 * @description build an options object based on the widgetTemplate
 * @param {String} widgetTemplate - e.g. LIST_HERO
 * @returns {Object}
 */
export const getListOptions = (widgetTemplate) =>
	({
		LIST_TEXT_ONLY: {},
		LIST_HERO_TEXT: { showHero },
		LIST_HERO_TEXT_THUMBS: { stacked, showHero, showThumbs, thumbType: '16x9' },
		LIST_TEXT_NUMBERS: { showCount },
		LIST_TEXT_THUMBS: { showThumbs, thumbType: '16x9' },
		LIST_TEXT_THUMBS_NUMBERS: { hideIcon, showThumbCount, showThumbs }
	}[widgetTemplate] || {});

/**
 * @function normalizeTemplateName
 * @param {String} widgetTemplate
 * @returns {String} normalized widget template name
 */
export const normalizeTemplateName = (widgetTemplate) =>
	widgetTemplate.toLowerCase().replace(/_/g, '-');
